import React, { FC, ReactNode } from "react";
import { FormLabel as FormLabel_ } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";

export interface FormLabelProps {
  children: ReactNode;
  htmlFor?: string;
}

export const FormLabel: FC<FormLabelProps> = (props) => {
  return (
    <FormLabel_ htmlFor={props.htmlFor} mb={"10px"}>
      <Texts.Normal display={"inline"}>{props.children}</Texts.Normal>
    </FormLabel_>
  );
};
