import React from "react";
import { Input, FormControl, FormErrorMessage, Text, Link, Flex } from "@chakra-ui/react";
import SharedNextTranslateLink from "src/shared/components/Controls/SharedNextTranslateLink";
import { APP_PASSWORD_FORGOTTEN_ROUTE } from "src/modules/smile/shared/consts";
import { useFormContext } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import { FormLabel } from "src/shared/components/FormLabel";

const SmileLoginFormPasswordInput = () => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();

  return (
    <FormControl id="password" isInvalid={!!errors.password}>
      <FormLabel>
        <Flex justify="space-between">
          <Text>{t("smile:smilePlusSignIn_passwordLabel")}</Text>
        </Flex>
      </FormLabel>
      <Input
        ref={register({
          required: t("smile:form_formRequiredField"),
        })}
        type={"password"}
        name="password"
        placeholder="xxxxxxx"
        height={"50px"}
        bg={"rgba(255, 255, 255, 0.8)"}
        border={"1px solid"}
        borderColor={"#BBD1F2"}
        borderRadius={"2px"}
        boxShadow={"0px 20px 32px rgba(70, 25, 164, 0.05)"}
        fontFamily="Montserrat"
        fontSize={"14px"}
        fontWeight={"500"}
        lineHeight={"110%"}
      />
      <Flex justifyContent="flex-end" alignItems="flex-end" mt="10px">
        <SharedNextTranslateLink href={APP_PASSWORD_FORGOTTEN_ROUTE} shallow>
          <Link>{t("smile:smilePlusSignIn_forgotPasswordLink")}</Link>
        </SharedNextTranslateLink>
      </Flex>
      <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
    </FormControl>
  );
};

export default SmileLoginFormPasswordInput;
