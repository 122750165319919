import React from "react";
import { Input, FormControl, FormErrorMessage, Text, Flex } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import { FormLabel } from "src/shared/components/FormLabel";

const SmileLoginFormEmailInput = () => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();

  return (
    <FormControl id="email" isInvalid={!!errors.email}>
      <FormLabel>
        <Flex justify="space-between">
          <Text>{t("smile:smilePlusSignIn_emailLabel")}</Text>
        </Flex>
      </FormLabel>
      <Input
        ref={register({
          required: t("smile:form_formRequiredField"),
        })}
        name="email"
        placeholder="E-mail"
        height={"50px"}
        bg={"rgba(255, 255, 255, 0.8)"}
        border={"1px solid"}
        borderColor={"#BBD1F2"}
        borderRadius={"2px"}
        boxShadow={"0px 20px 32px rgba(70, 25, 164, 0.05)"}
        fontFamily="Montserrat"
        fontSize={"14px"}
        fontWeight={"500"}
        lineHeight={"110%"}
      />
      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default SmileLoginFormEmailInput;
