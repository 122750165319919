import React, { useEffect } from "react";
import { Stack } from "@chakra-ui/react";

import { useForm, FormProvider } from "react-hook-form";
import { useAuth } from "src/shared/contexts/AuthContext";
import useTranslation from "next-translate/useTranslation";
import { Button } from "src/shared/components/Button";
import SmileLoginFormPasswordInput from "./SmileLoginFormPasswordInput";
import SmileLoginFormEmailInput from "./SmileLoginFormEmailInput";
import SmileSigninSwapLink from "../Controls/SmileSigninSwapLink";

interface FormData {
  email: string;
  password: string;
  validatePassword: string;
}

const SmileLoginForm = () => {
  const { t } = useTranslation();
  const formMethods = useForm<FormData>({
    mode: "onBlur",
  });
  const { handleSubmit, setError, formState } = formMethods;
  const [{ errorMessages }, { logUser }] = useAuth();

  const onSubmit = handleSubmit(async (values) => {
    await logUser(values.email, values.password);
  });

  useEffect(() => {
    if (errorMessages.length !== 0) {
      errorMessages.forEach((value) => {
        const field = value.includes("email") ? "email" : "password";
        setError(field, {
          type: "manual",
          message: value,
        });
      });
    }
  }, [errorMessages.length]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <Stack align="center" spacing="50px">
          <SmileLoginFormEmailInput />
          <SmileLoginFormPasswordInput />
          <Button type="submit" isLoading={formState.isSubmitting} isFullWidth={true}>
            {t("smile:smilePlusSignIn_continueButton")}
          </Button>
          <SmileSigninSwapLink />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SmileLoginForm;
