import { SubscriptionPlanStateEnum } from "src/shared/types/User";

export const hasAuthPermission = (planState = SubscriptionPlanStateEnum.ONBOARDING) => {
  //console.log(planState);
  return [
    SubscriptionPlanStateEnum.ACTIVE,
    SubscriptionPlanStateEnum.ACTIVE_WILL_DOWNGRADE,
    SubscriptionPlanStateEnum.ACTIVE_WILL_EXPIRE,
    SubscriptionPlanStateEnum.PAYMENT_PENDING,
    SubscriptionPlanStateEnum.TRIAL,
    SubscriptionPlanStateEnum.TRIAL_EXPIRED,
  ].includes(planState);
};
