import React, { useCallback } from "react";
import { FaAngleDoubleDown } from "react-icons/fa";
import Router from "next-translate/Router";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import NextImage from "next/image";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import SmileLoginForm from "./components/Forms/SmileLoginForm";
import { UDINI_HOME_ROUTE } from "src/utils/constants/routes";
import { IS_HYBRIDGE } from "src/modules/smile/shared/consts";
import * as consts from "src/shared/consts";

const SmileSignin = () => {
  const { t } = useTranslation();

  const handleGotoHome = useCallback(() => {
    Router.pushI18n(UDINI_HOME_ROUTE);
  }, []);

  const leftContent = IS_HYBRIDGE ? (
    <Flex h={"100%"} alignItems={"center"} justifyContent={"center"} position={"relative"} my={"37px"}>
      <Box my={"150px"}>
        <NextImage src="/svg/hybridge-logo.svg" alt="Hybridge logo" width="232px" height="62px" priority />
      </Box>
      <Box position={"absolute"} bottom={0} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Text
          fontFamily="Montserrat"
          fontStyle="normal"
          fontWeight="500"
          fontSize={"15px"}
          lineHeight="18px"
          mb={"20px"}
        >
          powered by
        </Text>
        <Box w={"95px"} cursor={"pointer"} onClick={handleGotoHome}>
          <NextImage src="/svg/UdiniLogoWhite.svg" alt="Udini logo" width="150px" height="75px" priority />
        </Box>
      </Box>
    </Flex>
  ) : (
    <Flex
      flexDirection="column"
      align="flex-start"
      width="100%"
      pl={{ base: "16px", md: "56px" }}
      pt={{ base: "42px", md: "47px" }}
      pb="47px"
      h="100%"
    >
      <Box
        display={{ base: "block", sm: "none" }}
        w={{ base: "100px", sm: "115px" }}
        mb={{ base: "45px", md: "unset" }}
        cursor={"pointer"}
        onClick={handleGotoHome}
      >
        <NextImage src="/svg/SmilePlusProductLogoWhite.svg" alt="Smile+ logo" width="150px" height="45px" priority />
      </Box>
      <Box
        display={{ base: "none", sm: "block" }}
        w={{ base: "100px", sm: "115px" }}
        mb={{ base: "45px", md: "unset" }}
        cursor={"pointer"}
        onClick={handleGotoHome}
      >
        <NextImage src="/svg/SmilePlusProductLogo.svg" alt="Smile+ logo" width="150px" height="45px" priority />
      </Box>
      <Stack flex={1} pl={{ md: "63px" }} pr={{ md: "109px" }} maxWidth="100%" h="400px" justifyContent="center">
        <Text
          fontFamily="Montserrat"
          fontStyle="normal"
          fontWeight="600"
          fontSize={{ base: "48px", sm: "56px" }}
          lineHeight="110%"
          mb={{ base: "20px", sm: "10px" }}
        >
          {t(`smile:smilePlusSignIn_leftViewTitle`)}
        </Text>
        <Box maxW="600px">
          <Text
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="normal"
            fontSize={{ base: "18px", sm: "17px" }}
            lineHeight="150%"
            letterSpacing="-0.02em"
          >
            {t(`smile:smilePlusSignIn_leftViewText`)}
          </Text>
        </Box>
      </Stack>
      <Box
        display={{ base: "none", sm: "block" }}
        w={{ base: "130px", sm: "116px" }}
        mt={{ base: "55px", md: "unset" }}
        cursor={"pointer"}
        onClick={handleGotoHome}
      >
        <NextImage src="/svg/udini-logo-dental-ai.svg" alt="Udini logo" width="150px" height="75px" priority />
      </Box>
      <Flex justify={"center"} w={"100%"} pr={"16px"} mt={"10px"} display={{ base: "flex", sm: "none" }}>
        <FaAngleDoubleDown size={"24px"} />
      </Flex>
    </Flex>
  );

  return (
    <>
      <Head>
        <title>{t("smile:smilePlusSignIn_pageTitle")}</title>
      </Head>
      <Flex
        w={{ sm: "100vw" }}
        h={{ sm: "100vh" }}
        flexDirection={{ base: "column", sm: "row" }}
        bg={
          IS_HYBRIDGE
            ? "white"
            : "linear-gradient(180deg, #F1FEFF -0.63%, #EDF7FF 22.95%, #FDFDFF 48.11%, #ECF6FF 69.08%, #FFFCFE 85.85%, #E7F3FF 100%)"
        }
        pos={"relative"}
      >
        {IS_HYBRIDGE ? null : (
          <Box pos={"absolute"} inset={0} overflow={"hidden"} zIndex={0}>
            <Box
              display={{ base: "block", sm: "none" }}
              position={"absolute"}
              left={"-250px"}
              top={"-250px"}
              width={"200%"}
              pt={"200%"}
              borderRadius={"50%"}
              bg={"linear-gradient(109.91deg, #FC2C54 16.04%, #FFA7DC 75%)"}
              style={{
                filter: "blur(70px)",
              }}
            />
            <Box
              display={{ base: "none", sm: "block" }}
              position={"absolute"}
              left={0}
              top={"50%"}
              width={"50%"}
              pt={"50%"}
              transform={"translate(-10%, -50%)"}
              borderRadius={"50%"}
              bg={"linear-gradient(109.91deg, #FC2C54 16.04%, #FFA7DC 75%)"}
              style={{
                filter: "blur(70px)",
              }}
            />
          </Box>
        )}
        <Flex w={{ sm: "57vw" }} h={{ sm: "100%" }} zIndex={1} bg={IS_HYBRIDGE ? consts.COLOR_RED : undefined}>
          <Stack color="white" align="center" width="100%">
            {leftContent}
          </Stack>
        </Flex>
        <Flex
          w={{ sm: "43vw" }}
          px={{ base: "16px", md: "100px" }}
          pt={{ base: "40px", sm: "15px" }}
          pb={{ base: "65px", sm: "unset" }}
          direction="column"
          zIndex={1}
        >
          <Stack as="main" spacing="50px">
            <Text
              mt={{ base: "120px", sm: "200px" }}
              fontFamily="Montserrat"
              fontStyle="normal"
              fontWeight="600"
              fontSize={{ base: "48px", sm: "40px" }}
              lineHeight="100%"
              letterSpacing="-0.03em"
            >
              {t(`smile:smilePlusSignIn_rightViewTitle`)}
            </Text>
            <SmileLoginForm />
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default SmileSignin;
