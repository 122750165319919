import React from "react";
import { Text, Link } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import SharedNextTranslateLink from "src/shared/components/Controls/SharedNextTranslateLink";
import { APP_SIGNUP_ROUTE } from "src/modules/smile/shared/consts";

const SmileSigninSwapLink = () => {
  const { t } = useTranslation();

  return (
    <Text marginTop="40px" fontWeight="bold" fontSize="14px" lineHeight="17px" textAlign="center">
      {t("smile:smilePlusSignIn_noAccountText")}{" "}
      <SharedNextTranslateLink href={APP_SIGNUP_ROUTE} passHref>
        <Link>{t("smile:smilePlusSignIn_noAccountLink")}</Link>
      </SharedNextTranslateLink>
    </Text>
  );
};

export default SmileSigninSwapLink;
