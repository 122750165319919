import React, { useEffect } from "react";
import { pushRoute } from "src/utils/constants/routes";
import useTranslation from "next-translate/useTranslation";
import { hasAuthPermission } from "src/utils/hasAuthPermission";
import WithWaitForAuthentication from "src/shared/hocs/WithWaitForAuthentication";
import { useAuthState } from "src/shared/contexts/AuthContext";
import { Flex, Spinner } from "@chakra-ui/react";
import SmileSignin from "src/modules/smile/pages/Signin";
import { APP_HOME_ROUTE, APP_SIGNUP_ROUTE } from "src/modules/smile/shared/consts";

const SmileSignInPage = () => {
  const { isAuthenticated, profile } = useAuthState();
  const { lang } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && profile) {
      if ("smilePlusStatePlan" in profile) {
        if (hasAuthPermission(profile?.smilePlusStatePlan)) {
          pushRoute(APP_HOME_ROUTE, lang);
        } else {
          pushRoute(APP_SIGNUP_ROUTE, lang);
        }
      }
    }
  }, [isAuthenticated, profile]);

  if (profile && !("smilePlusStatePlan" in profile)) {
    return (
      <Flex h="100vh" w="100vw" justify="center" align="center">
        <Spinner size="xl" color="brandPink.100" thickness="5px" />
      </Flex>
    );
  }

  return <SmileSignin />;
};

export default WithWaitForAuthentication(SmileSignInPage);
