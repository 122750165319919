import React from "react";
import { useAuthState } from "src/shared/contexts/AuthContext";
import { Flex, Spinner } from "@chakra-ui/react";

const WithWaitForAuthentication = (Component: React.FC) => (props: any) => {
  const { hasRefreshToken, isAuthenticated } = useAuthState();

  if (hasRefreshToken && !isAuthenticated) {
    return (
      <Flex h="100vh" w="100vw" justify="center" align="center">
        <Spinner size="xl" color="brandBlue.100" thickness="5px" />
      </Flex>
    );
  }

  return <Component {...props} />;
};

export default WithWaitForAuthentication;
